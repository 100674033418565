* {
  scrollbar-color: var(--mui-palette-text-disabled) transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: var(--mui-palette-text-disabled);
  border: 1px solid transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

body {
  overflow: hidden;
  overscroll-behavior: none;
}

.background {
  height: 100vh;
  left: 0;
  object-fit: cover;
  object-position: center top;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  will-change: transform;
  z-index: -1;
}

.dark .background {
  filter: brightness(25%);
}
